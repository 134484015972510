import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Kasinot extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' title='Trustly' alt='trustly' class='lazyload' width='75px' height='50px'/>"
			var perinteiset = "<img src='/images/maksu/perinteiset.webp' title='Perinteiset maksutavat' alt='perinteiset' class='lazyload' width='75px' height='50px'/>"
			var brite = "<img src='/images/maksu/brite.webp' alt='brite' title='Brite' class='lazyload' width='75px' height='50px'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' title='Zimpler' alt='zimpler' class='lazyload' width='75px' height='50px'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Brite", brite).replace("Perinteiset", perinteiset).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}

    var lisenssi = document.getElementsByClassName("lisenssi");
		var o;
		for (o = 0; o < lisenssi.length; o++) {
			var malta = "<img src='/images/lisenssi/mga.webp' title='Maltan lisenssi' alt='Maltan lisenssi' class='lazyload' width='75px' height='75px'/>"
			var viro = "<img src='/images/lisenssi/maksu-tolliamet.webp' title='Viron lisenssi' alt='Viron lisenssi' class='lazyload' width='75px' height='75px'/>"
      var curacao = "<img src='/images/lisenssi/curacao.webp' title='Curacaon lisenssi' alt='Curacaon lisenssi' class='lazyload' width='75px' height='75px'/>"
			var str = lisenssi[o].innerHTML;

			var res = str.replace("Malta", malta).replace("Viro", viro).replace("Curacao", curacao);
			lisenssi[o].innerHTML = res;
		}

		
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "kasino2025"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
            kasinonListanimi
            lause1
            ilmaiskierrokset
            rahansiirto
            lisenssi
            logonTiedostonimi
            afflink
            kasinonLisatiedot {
              json
              }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        }
      }
      `}
      render={data => (
    <Layout>
      <GatsbySeo
      title='Kasino 2025 | Uudet virtuaaliset peliluolat koottuna samalle sivulle!'
      description='Vuoden 2025 uudet kasinot luetteloituna. Selvällä selkokielellä! Tutustu ja valitse paras uusi nettikasino.'
   
   />
        <FAQJsonLd
            questions={[
              { question: 'Mitä tarkoitetaan uudella kasinolla?', answer: 'Uusi kasino voi olla joko täysin uusi pelipaikka tai itsensä uudelleen brändännyt vanhempi kasino. Molempia yhdistää se, että ne pyrkivät tarjoamaan pelaajan näkökulmasta kaikin tavoin ensiluokkaisen pelikokemuksen.' },
              { question: 'Mistä löydän parhaat uudet kasinot 2025?', answer: 'Paras kasino on monesti makuasia, joten kasinoiden sivuilla kannattaa pyöriä ja ruotia niiden yksityiskohtia tarkemmin. Päivitämme myös tähän sivustollemme mielestämme parhaat kasinot heti, kun kiinnostavia uutuuksia julkaistaan.' },
              { question: 'Miten uusien kasinoiden bonukset eroavat aiemmista?', answer: 'Uudet kasinot kohtaavat aiempaa kovempaa kilpailua, ja ne ovat joutuneet muokkaamaan bonusehtojaan pelaajien kannalta mielekkäämpään suuntaan. Esimerkiksi kierrätysvaatimusten poistuminen tai kohtuullistaminen näkyvät tällä hetkellä selvästi uusilla kasinoilla.' },
              { question: 'Ovatko uudet kasinot turvallisempia kuin vanhat?', answer: 'Turvallisuus on monitahoinen käsite, eikä uusi kasino ole automaattisesti vanhaa turvallisempi. Suurin osa kasinoista ei kuitenkaan tee turvallisuuden suhteen minkäänlaisia kompromisseja, joten voit pelata niin uusilla kuin vanhoilla kasinoilla huoletta. Turvallisuudesta kertovat enemmän muut seikat, esimerkiksi kasinon hallussa oleva pelilisenssi.' },
              { question: 'Murtuuko Veikkauksen monopoli vuonna 2025?', answer: 'Kuten moni tietää, Veikkauksen kasinomonopoli on toden totta vihdoin murtumassa, mikä avaa ovet aitojen suomalaisten kasinoiden syntymisellä. Ei kuitenkaan ole kovin todennäköistä, että muutos astuisi voimaan vielä vuonna 2025, sillä lainsäädäntö on asian suhteen vasta alkutekijöissään.' },
            ]}
          />
        <Container>
        <div class="suodatin">
          <p><b>Aina ajan hermolla!</b></p>
        <p><i>On taas koittanut aika jännittää, mitä uutta kasinomaailmassa tapahtuu vuonna 2025 ja mikä uusi casino on pelaajien puheenaiheena turuilla ja toreilla juuri nyt. Tällä sivulla käymme läpi kattavasti, millaisia pelipaikkoja uudet kasinot ovat ja miten uusien tulokkaiden voi arvioida eroavan nykytarjonnasta.</i></p>
        <p><i>Voit tutustua alta kaikista uusimpiin vuoden 2025 kasinoihin. Esittelemme hakemistossa vain 100% bränikät nettikasinot!</i></p>
                    </div>
        <table>
          <tbody>
            {data.contentfulMoneysivujenOsiot.kasinolista.map(({logonTiedostonimi,kasinonListanimi,lause1,ilmaiskierrokset,afflink,kasinonLisatiedot,rahansiirto,lisenssi}) => (
            <tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
                <img data-src={`/images/a/${logonTiedostonimi}`} alt={kasinonListanimi} title={kasinonListanimi} className="lazyload" width="120" height="50"/>
									<div className={casinolistStyle.name}>
                  <a href={afflink} target="_blank"><span className={casinolistStyle.title}><b>{kasinonListanimi}</b></span></a>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<p>&#128280; {lause1}</p>
                        <p>&#128280; {ilmaiskierrokset}</p>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={afflink} target="_blank">Kasinolle &#8618;</a>
                      <div className="talletus">{rahansiirto}</div>
                  	</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>

								<div className={casinolistStyle.casinoalaosa}>
                <div className="lisenssi">{lisenssi}</div>
                {documentToReactComponents(kasinonLisatiedot.json)}
								</div>
							</td>
						</tr>
            ))}
          </tbody>
        </table>
        <div className="ylaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
        })}</div>
        <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
        <div className="alaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
        <div class="suodatin3">
        <center><h3>Näitä meiltä kysellään usein:</h3></center>
                <div>
                  <div><h4>&#128204; Mitä tarkoitetaan uudella kasinolla? </h4></div>
                  <p>Uusi kasino voi olla joko täysin uusi pelipaikka tai itsensä uudelleen brändännyt vanhempi kasino. Molempia yhdistää se, että ne pyrkivät tarjoamaan pelaajan näkökulmasta kaikin tavoin ensiluokkaisen pelikokemuksen.</p>
                </div>
                <div>
                  <div><h4>&#128204; Mistä löydän parhaat uudet kasinot 2025? </h4></div>
                  <p>Paras kasino on monesti makuasia, joten kasinoiden sivuilla kannattaa pyöriä ja ruotia niiden yksityiskohtia tarkemmin. Päivitämme myös sivustollamme mielestämme parhaat kasinot heti, kun kiinnostavia uutuuksia julkaistaan.</p>
                </div>                
                <div>
                  <div><h4>&#128204; Miten uusien kasinoiden bonukset eroavat aiemmista?</h4></div>
                  <p>Uudet kasinot kohtaavat aiempaa kovempaa kilpailua, ja ne ovat joutuneet muokkaamaan bonusehtojaan pelaajien kannalta mielekkäämpään suuntaan. Esimerkiksi kierrätysvaatimusten poistuminen tai kohtuullistaminen näkyvät tällä hetkellä selvästi uusilla kasinoilla.</p>
                </div>     
                <div>
                  <div><h4>&#128204; Ovatko uudet kasinot turvallisempia kuin vanhat? </h4></div>
                  <p>Turvallisuus on monitahoinen käsite, eikä uusi kasino ole automaattisesti vanhaa turvallisempi. Suurin osa kasinoista ei kuitenkaan tee turvallisuuden suhteen minkäänlaisia kompromisseja, joten voit pelata niin uusilla kuin vanhoilla kasinoilla huoletta. Turvallisuudesta kertovat enemmän muut seikat, esimerkiksi kasinon hallussa oleva pelilisenssi.</p>
                </div> 
                <div>
                  <div><h4>&#128204; Murtuuko Veikkauksen monopoli vuonna 2025? </h4></div>
                  <p>Kuten moni tietää, Veikkauksen kasinomonopoli on toden totta vihdoin murtumassa, mikä avaa ovet aitojen, uusien suomalaisten kasinoiden syntymiselle. Ei kuitenkaan ole kovin todennäköistä, että muutos astuisi voimaan vielä vuonna 2025, sillä lainsäädäntö on asian suhteen vasta alkutekijöissään.</p>
                </div>            
              </div>
      
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Kasinot

